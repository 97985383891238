// src/App.js
import React, { useState } from "react";
import useGoogleAuth from "./googleAuth";
import MapView from "./MapView"; // Your main map component

const App = () => {
  const [user, setUser] = useState(null);

  // Initialize Google authentication and provide the callback to set the authenticated user
  const { signIn, signOut } = useGoogleAuth((authenticatedUser) => {
    setUser(authenticatedUser);
  });

  return (
    <div>
      {user ? (
        <>
          <p>Welcome, {user.name}!</p>
          <img src={user.imageUrl} alt="User Profile" />
          <button onClick={signOut}>Logout</button>
          <MapView userId={user.id} /> {/* Pass the user ID to differentiate maps */}
        </>
      ) : (
        <>
          <h1>Please sign in to view your map</h1>
          <button onClick={signIn}>Sign in with Google</button>
        </>
      )}
    </div>
  );
};

export default App;
