// src/MapView.js
import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, Polyline, useLoadScript } from "@react-google-maps/api";
import supabase from "./supabaseClient";
import "./MapView.css";

const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const initialCenter = {
  lat: 37.7749, // Default center (San Francisco)
  lng: -122.4194,
};

const MapView = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBqTcC9lk_-5vUBXljrlyP5Y9lickotgFA", // Replace with your API key
  });

  const [center, setCenter] = useState(initialCenter);
  const [markers, setMarkers] = useState([]);
  const [route, setRoute] = useState([]);
  const [tracking, setTracking] = useState(false);
  const [myLocation, setMyLocation] = useState(null);
  const [user, setUser] = useState(null); // State for the authenticated user

  // States for note editing
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);
  const [noteText, setNoteText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  // Fetch the authenticated user
  const fetchUser = async () => {
    const { data, error } = await supabase.auth.getUser(); // Updated method to get user
    if (error) {
      console.error("Error fetching user:", error.message);
    } else {
      setUser(data.user);
    }
  };

  // Load markers from Supabase, ordered by the `order` field
  const loadMarkers = async () => {
    const { data, error } = await supabase
      .from("markers")
      .select("*")
      .order("order", { ascending: true });

    if (error) {
      console.error("Error loading markers:", error.message);
    } else {
      setMarkers(data);
    }
  };

  // Load route from Supabase
  const loadRoute = async () => {
    if (!user) return;

    const { data, error } = await supabase
      .from("routes")
      .select("path")
      .eq("user_id", user.id) // Use user.id
      .single();

    if (error) {
      console.error("Error loading route:", error.message);
    } else if (data) {
      setRoute(data.path);
    }
  };

  // Save route to Supabase
  const saveRoute = async (newRoute) => {
    if (!user) return;

    const { error } = await supabase
      .from("routes")
      .upsert({
        user_id: user.id, // Use user.id
        path: newRoute,
        updated_at: new Date()
      });

    if (error) {
      console.error("Error saving route:", error.message);
    }
  };

  // Add a new marker with a sequential `order` value
  const addMarker = async (lat, lng) => {
    console.log("Adding marker:", { lat, lng }); // Debugging log
    const newOrder = markers.length + 1; // New marker order is the next sequential number
    const { data, error } = await supabase
      .from("markers")
      .insert([{ lat, lng, order: newOrder, createdAt: new Date() }])
      .select();

    if (error) {
      console.error("Error adding marker:", error.message);
    } else {
      console.log("Marker added:", data); // Debugging log
      setMarkers((current) => [...current, ...data]);
    }
  };

  // Update marker attributes without changing the `order` field
  const updateMarker = async (id, updatedValues) => {
    const marker = markers.find((m) => m.id === id); // Find the existing marker
    const updatedMarker = { ...marker, ...updatedValues }; // Preserve `order` by not changing it

    const { error } = await supabase.from("markers").update(updatedMarker).eq("id", id);
    if (error) {
      console.error("Error updating marker:", error.message);
    } else {
      setMarkers((current) =>
        current.map((m) => (m.id === id ? { ...m, ...updatedValues } : m))
      );
    }
  };

  // Remove a marker and reorder the remaining markers sequentially
  const removeMarker = async (id) => {
    const { error } = await supabase.from("markers").delete().eq("id", id);
    if (error) {
      console.error("Error deleting marker:", error.message);
    } else {
      setMarkers((current) => current.filter((marker) => marker.id !== id));
      await reorderMarkers(); // Reorder the remaining markers sequentially
    }
  };

  // Reorder all markers after a deletion
  const reorderMarkers = async () => {
    for (let i = 0; i < markers.length; i++) {
      await supabase.from("markers").update({ order: i + 1 }).eq("id", markers[i].id);
    }
  };

  // Handle map clicks to add a new marker
  const onMapClick = useCallback(
    (event) => {
      console.log("Map clicked at:", event.latLng); // Debugging log
      addMarker(event.latLng.lat(), event.latLng.lng());
    },
    [markers]
  );

  // Start or stop live route tracking
  const toggleTracking = () => {
    if (!tracking) {
      navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setRoute((current) => {
            const newRoute = [...current, { lat: latitude, lng: longitude }];
            saveRoute(newRoute); // Save route whenever it's updated
            return newRoute;
          });
          setMyLocation({ lat: latitude, lng: longitude });
        },
        (error) => console.error(error),
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    }

    setTracking(!tracking);
  };

  // Center the map on the user's current location
  const showMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
        setMyLocation({ lat: latitude, lng: longitude });
      });
    }
  };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString() : "N/A";
  };

  // Function to save the note and title, and update the modification timestamp
  const saveNoteAndTitle = () => {
    const now = new Date();
    setMarkers((current) =>
      current.map((marker, index) =>
        index === selectedMarkerIndex
          ? { ...marker, note: noteText, title: titleText, updatedAt: now } // Update the note and title
          : marker
      )
    );
    setIsEditing(false); // Switch back to viewing mode
  };

  let myLocationIcon = {};
  if (isLoaded && window.google) {
    myLocationIcon = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 8,
      fillColor: "#4285F4",
      fillOpacity: 1,
      strokeColor: "white",
      strokeWeight: 2,
    };
  }

  useEffect(() => {
    fetchUser();
    loadMarkers();
  }, []);

  useEffect(() => {
    if (user) {
      loadRoute();
    }
  }, [user]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={12}
            center={center}
            onClick={onMapClick}
          >
            {markers.map((marker, index) => (
              <Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
                label={`${marker.order}`} // Use the fixed `order` field for numbering
                onClick={() => setSelectedMarkerIndex(index)} // Set selected marker for editing
              />
            ))}

            <Polyline path={route} options={{ strokeColor: "#FF0000", strokeWeight: 2 }} />

            {myLocation && (
              <Marker
                position={myLocation}
                icon={myLocationIcon} // Custom icon using SymbolPath
              />
            )}
          </GoogleMap>
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
          {/* List of Markers */}
          <h3>List of Markers:</h3>
          <ol>
            {markers.map((marker, index) => (
              <li
                key={marker.id}
                onClick={() => setSelectedMarkerIndex(index)}
                style={{ cursor: "pointer" }}
              >
                {`Marker ${marker.order}`}
              </li>
            ))}
          </ol>

          {/* Marker Note Editing Section */}
          {selectedMarkerIndex !== null && markers[selectedMarkerIndex] && (
            <div style={{ marginTop: "20px" }}>
              <h3>Marker {selectedMarkerIndex + 1}</h3>
              <p>
                Created At: {formatDate(markers[selectedMarkerIndex].created_at)}
                <br />
                Last Modified: {formatDate(markers[selectedMarkerIndex].updated_at)}
              </p>
              {!isEditing ? (
                <div>
                  <p>Title: {markers[selectedMarkerIndex].title || `Marker ${selectedMarkerIndex + 1}`}</p>
                  <p>Note: {markers[selectedMarkerIndex].note || "No notes available"}</p>
                  <button onClick={() => setIsEditing(true)}>Edit Note and Title</button>
                  <button onClick={() => removeMarker(markers[selectedMarkerIndex].id)}>Remove Marker</button>
                </div>
              ) : (
                <div>
                  <label>Title:</label>
                  <input
                    type="text"
                    value={titleText}
                    onChange={(e) => setTitleText(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  <label>Note:</label>
                  <textarea
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    style={{ width: "100%", height: "80px" }}
                  />
                  <button onClick={saveNoteAndTitle}>Save Note and Title</button>
                  <button onClick={() => setIsEditing(false)}>Cancel</button>
                </div>
              )}
            </div>
          )}

          {/* Route Details Section */}
          <h3>Route Details:</h3>
          <p>Total Points: {route.length}</p>
          {route.length > 0 && (
            <>
              <p>Start Point: {route[0].lat}, {route[0].lng}</p>
              <p>End Point: {route[route.length - 1].lat}, {route[route.length - 1].lng}</p>
            </>
          )}
        </div>
      </div>

      {/* Buttons for live tracking, location, etc. */}
      <button onClick={showMyLocation}>Show My Location</button>
      <button onClick={toggleTracking}>{tracking ? "Stop Tracking" : "Start Tracking"}</button>
    </div>
  );
};

export default MapView;
