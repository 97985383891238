// src/googleAuth.js
import { gapi } from "gapi-script";
import { useEffect } from "react";

const CLIENT_ID = "741803599681-1ju0oo79ptoucvn764btq0r7ff9r6uc6.apps.googleusercontent.com";

const SCOPES = "https://www.googleapis.com/auth/userinfo.email";

const useGoogleAuth = (onAuthSuccess) => {
  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          clientId: CLIENT_ID,
          scope: SCOPES,
        })
        .then(() => {
          gapi.auth2.getAuthInstance().isSignedIn.listen((signedIn) => {
            if (signedIn) {
              const user = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
              onAuthSuccess({
                id: user.getId(),
                name: user.getName(),
                email: user.getEmail(),
                imageUrl: user.getImageUrl(),
              });
            }
          });

          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            const user = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
            onAuthSuccess({
              id: user.getId(),
              name: user.getName(),
              email: user.getEmail(),
              imageUrl: user.getImageUrl(),
            });
          }
        });
    };

    gapi.load("client:auth2", initClient);
  }, [onAuthSuccess]);

  const signIn = () => {
    gapi.auth2.getAuthInstance().signIn();
  };

  const signOut = () => {
    gapi.auth2.getAuthInstance().signOut();
  };

  return { signIn, signOut };
};

export default useGoogleAuth;
