// src/supabaseClient.js
import { createClient } from "@supabase/supabase-js";

// Supabase project credentials
const supabaseUrl = "https://qwsohefnmvxtqicbtcer.supabase.co"; // Your project URL
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF3c29oZWZubXZ4dHFpY2J0Y2VyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwMjA3NzYsImV4cCI6MjAzMDU5Njc3Nn0.m5NP9u-xBfeb_I_8SH5R-Q9_Q4lbNIrp1oDXpRRwUhE"; // Your public anon key

// Create and export Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey);
export default supabase;
